const CONFIG = {
  url: process.env.VUE_APP_API_ENDPOINT,
  appName: process.env.VUE_APP_NAME,
  clientId: localStorage.getItem("apiSecret") ? process.env.VUE_APP_CLIENT_ID_EDITOR : process.env.VUE_APP_CLIENT_ID,
  clientSecret: localStorage.getItem("apiSecret") ? localStorage.getItem("apiSecret") : process.env.VUE_APP_CLIENT_SECRET
};

////////  Bearer Token manupulation
function getBearerToken() {
  return localStorage.getItem("token");
}

function setBearerToken(token) {
  localStorage.setItem("token", token);
}

function clearBearerToken() {
  localStorage.removeItem("token");
}

export async function fetchBearerToken() {
  // Check if we have already a bearer token in local store.
  let token = getBearerToken();

  if (token) {
    return token;
  }

  const params = new URLSearchParams(
    "grant_type=client_credentials&scope=squidex-api"
  );
  params.append("client_id", CONFIG.clientId);
  params.append("client_secret", CONFIG.clientSecret);

  // Get the bearer token. Ensure that we use a client id with readonly permissions.
  const response = await fetch(buildUrl("identity-server/connect/token"), {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: params.toString()
  });

  if (!response.ok) {
    throw new Error(`Failed to retrieve token, got ${response.statusText}`);
  }

  const json = await response.json();

  token = json.access_token;

  // Cache the bearer token in the local store.
  setBearerToken(token);

  return token;
}

////////  Internal functions

function buildUrl(url) {
  if (url.length > 0 && url.startsWith("/")) {
    url = url.substr(1);
  }

  const result = `${CONFIG.url}/${url}`;

  return result;
}

async function getContent(url, lang) {
  // console.log("getContent called with URL: "+url)
  return await getContentInternal(url, true, lang);
}

async function getContentInternal(url, retry, lang) {
  // console.log("getContentInternal called with URL: "+url)
  // Fetch the bearer token.
  const token = await fetchBearerToken();

  const response = await fetch(buildUrl(url), {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "X-Languages": lang ? "" : ""
    }
  });
  // console.log("getContentInternal response: ")
  // console.log(response)
  if (!response.ok) {
    if (
      response.status === 404 ||
      response.status === 403 ||
      response.status === 401
    ) {
      // If we get an error we clear the bearer token and retry the request.
      clearBearerToken();

      if (retry) {
        return getContentInternal(url);
      }
    }

    throw new Error(`Failed to retrieve content, got ${response.statusText}`);
  }

  return await response.json();
}

async function parseData(response, lang, includeContent = true) {
  let parsedData = {};

  parsedData["created"] = response.created;
  parsedData["lastModified"] = response.lastModified;
  parsedData["schemaName"] = response.schemaName;
  parsedData["status"] = response.status;

  Object.keys(response.data).forEach(item => {
    if (item == "slug") {
      parsedData[item] = response.data[item];
    } else if (item != "content") {
      // console.log(lang)
      parsedData[item] = response.data[item][lang]
        ? response.data[item][lang]
        : response.data[item]["iv"];
    }
  });

  // console.log(response.data.content.iv)
  if (response.data.content && includeContent) {
    parsedData["content"] = null;
    const content = await getContentByID(response.data.content.iv, lang);

    // content.items.forEach(async function(g,i){
    //   parsedData["content"][i] = await parseContent(g, lang)
    // }

    let promises2 = content.items.map(g => parseContent(g, lang, true));

    await Promise.all(promises2).then(results => {
      parsedData["content"] = results;
      // console.log("RESULT2")
      // console.log(parsedData["content"])
    });
  }

  if (response.data.homepage) {
    const content = await getContentByID(response.data.homepage.iv, lang);
    parsedData["homepage"] = await parseContent(content.items[0], lang);
  }

  return parsedData;
}

async function parseMenu(response, lang) {
  let parsedData = {};

  parsedData["created"] = response.created;
  parsedData["lastModified"] = response.lastModified;

  // console.log("parseMenu");
  if (response.data.content) {
    parsedData["content"] = null;
    const content = await getContentByID(response.data.content.iv, lang);

    const promises = content.items.map(menuItem =>
      parseContent(menuItem, lang, menuItem.schemaName == "submenu")
    );
    await Promise.all(promises).then(results => {
      parsedData["content"] = results;
    });
  }

  if (response.data.homepage) {
    const content = await getContentByID(response.data.homepage.iv, lang);
    parsedData["homepage"] = await parseContent(content.items[0], lang, false);
  }

  return parsedData;
}

async function parseContent(response, lang, subcontent = "true") {
  let parsedContent = {};

  parsedContent["created"] = response.created;
  parsedContent["lastModified"] = response.lastModified;
  parsedContent["schemaName"] = response.schemaName;
  parsedContent["status"] = response.status;
  parsedContent["cmsid"] = response.id;

  if (response.data) {
    Object.keys(response.data).forEach(async function(item) {
      if (item == "slug") {
        parsedContent[item] = response.data[item];
      } else if (!item || item != "content") {
        parsedContent[item] = response.data[item][lang]
          ? response.data[item][lang]
          : response.data[item]["iv"];
      }
    });

    if (response.data.content && subcontent) {
      let subcontent = {};
      const contents = await getContentByID(response.data.content.iv);

      if(response.schemaName == "group" && response.data.orderBy){
        if (response.data.orderBy.iv == "time:desc"){
          contents.items.sort(function(a, b) {
               return new Date(b.data.publishDate.iv) - new Date(a.data.publishDate.iv);
           });
        }
      }
      contents.items.forEach(async function(g, i) {
        subcontent[i] = await parseContent(g, lang, true);
        parsedContent["content"] = subcontent;
      });
    }
    return parsedContent;
  }
}

///////  Exported functions

/// Get content(s) by its ID(s)
export async function getContentByID(contentId, lang) {
  return await getContent(
    `api/content/${CONFIG.appName}?ids=${contentId}`,
    lang
  );
}

export async function getContentBySlug(type = "", slug, lang) {
  const data = await getData(
    type,
    lang,
    `?$filter=data/slug/${lang} eq '${slug}'`
  );
  return data[0];
}

export async function getMenu(lang) {
  const data = await getContent(`api/content/${CONFIG.appName}/menu`, lang);

  let finalData = {};
  const promises1 = data.items.map(x => parseMenu(x, lang));
  // console.log(promises1)
  await Promise.all(promises1).then(results => {
    finalData = results;
  });

  return finalData;
}

export async function getData(type, lang, filter) {
  const json = await getContent(
    `api/content/${CONFIG.appName}/${type}` + filter,
    lang
  );

  let finalData = {};
  const promises1 = json.items.map(x => parseData(x, lang));
  // console.log(promises1)
  await Promise.all(promises1).then(results => {
    finalData = results;
  });

  return finalData;
}

export async function updateContent(type, id, content, retry=true){

  const token = await fetchBearerToken();

  const response = await fetch(buildUrl(`/api/content/${CONFIG.appName}/${type}/${id}`), {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(content)
  });

  if (!response.ok) {
    if (
      response.status === 404 ||
      response.status === 403 ||
      response.status === 401
    ) {
      // If we get an error we clear the bearer token and retry the request.
      clearBearerToken();

      if (retry) {
        return updateContent(type, id, content, false);
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }
  else {
    return true;
  }

}


export async function removeContent(type, id, retry=true){

  const token = await fetchBearerToken();

  const response = await fetch(buildUrl(`/api/content/${CONFIG.appName}/${type}/${id}`), {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });

  if (!response.ok) {
    if (
      response.status === 404 ||
      response.status === 403 ||
      response.status === 401
    ) {
      // If we get an error we clear the bearer token and retry the request.
      clearBearerToken();

      if (retry) {
        return removeContent(type, id, false);
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }
  else {
    return true;
  }

}



export async function uploadAsset(formData, retry=true){

  const token = await fetchBearerToken();

  const response = await fetch(buildUrl(`/api/apps/${CONFIG.appName}/assets`), {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`
    },
    body: formData
  });

  if (!response.ok) {
    if (
      response.status === 404 ||
      response.status === 403 ||
      response.status === 401
    ) {
      // If we get an error we clear the bearer token and retry the request.
      clearBearerToken();

      if (retry) {
        return uploadAsset(formData, false);
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }
  else {
    return await response.json();
  }

}



export async function replaceAsset(formData, id, retry=true){

  const token = await fetchBearerToken();

  const response = await fetch(buildUrl(`/api/apps/${CONFIG.appName}/assets/${id}/content`), {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`
    },
    body: formData
  });

  if (!response.ok) {
    if (
      response.status === 404 ||
      response.status === 403 ||
      response.status === 401
    ) {
      // If we get an error we clear the bearer token and retry the request.
      clearBearerToken();

      if (retry) {
        return replaceAsset(formData, id, false);
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }
  else {
    return await response.json();
  }

}
