<template>

  <div v-if="data" :id="data.id"
  :class="[
  'content', 'mx-auto', 'text-center',
    {
      /* 'd-flex justify-center': data.id == 'o-nas', */
    },
  ]" >
    <General v-for="(item, i) in data.content" :data="item" v-bind:key="i" />
  </div>
  <div v-else-if="notFound">
    <NotFound />
  </div>
  <div v-else>
    Načítám ...
  </div>
</template>

<script>

// import { useStore } from "vuex";

import General from '../components/General/General.vue';
import NotFound from './NotFound.vue';

import { getContentBySlug } from "../api/api.js";

export default {
  name: 'Page',
  components: {
    General,
    NotFound
  },
  props: {
    initSlug: String
  },
  data: () => ({
    data: {},
    slug: '',
    notFound: false
  }),
  methods: {
    getPage: async function(slug){
      try {
        return await getContentBySlug('page', slug, this.$lang);
      } catch (ex) {
        console.log(ex);
        return null;
      }
    },
    log(msg){
      console.log(msg)
    }
  },
  setup(){
    // const store = useStore();
    // function updateSlugs(){
    //   store.commit("updateSlugs", this.data.slug);
    // }
    // return { updateSlugs }
  },
  async created() {
    // console.log(this.initSlug)
    this.slug = this.$route.params.slug ? this.$route.params.slug : this.initSlug;
    // console.log(this.slug)
    this.data = await this.getPage(this.slug);

    if(!this.data){
      this.notFound = true
    }

    // this.updateSlugs();
  },
  // watch: {
  //   $route: {
  //       handler: async function() {
  //           if(this.$route.params.lang){
  //             this.$lang = this.$route.params.lang
  //           }
  //           this.slug = this.$route.params.slug ? this.$route.params.slug : this.initSlug;
  //           this.data = await this.getPage(this.slug);
  //           this.updateSlugs();
  //       }
  //   }
  // },

}
</script>

<style lang="scss">
  div.content{
    // min-height: 62%;
    // padding-bottom: 95px;
  }
</style>
