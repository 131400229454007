<template v-if="data">
  <div class="text container mt-5">
    <transition name="slide-fade">
      <v-alert
        class="notification"
        type="success"
        elevation="5"
        dense
        v-if="savedNotification"
        >Uloženo</v-alert
      >
      <v-alert
        class="notification"
        type="error"
        elevation="5"
        dense
        v-if="failedNotification"
        >Došlo k chybě!</v-alert
      >
    </transition>
    <v-row  justify="center">
      <v-col cols=12 sm=11 md=10 lg=8>
    <h2 class="title">{{ data.title }}</h2>
    <p class="text-black-50">{{ formatDate(data.publishDate) }}</p>
    <p
      v-if="perexLength > 0"
      class="description"
      v-html="truncate(data.text, perexLength)"
    ></p>
    <p
      v-else-if="!$editor"
      class="description"
      :data-id="data.cmsid"
      v-html="data.text"
    ></p>
    <InlineEditor v-else-if="$editor" v-model="data.text" @input="save" />
    <!-- <editor
      v-else-if="$editor"
      api-key="zolxn78r02vezvgzijqlcrn6la4kh9tjxb1wi0q1siwa6nom"

      v-model="data.text"
      :inline="true"
      :init="{
        plugins: ['link lists paste'],
        menubar: '',
        Xlanguage: 'cs_CZ',
        toolbar:
          'bold italic underline | removeformat| alignleft aligncenter alignright alignjustify | fontsizeselect forecolor | bullist |undo redo ',
      }"
      tag-name="div"
      tooxlbar="bold italic underline fontsizes align link removeformat"
      @input="save"
    /> -->

  </v-col>
</v-row>
  </div>

</template>
<script>
import "./Text.scss";
import { truncate } from "../../helpers/truncate";
import { formatDate } from "../../helpers/formatDate";
import { updateContent } from "../../api/api.js";

import InlineEditor from "../InlineEditor.vue";

// import Editor from "@tinymce/tinymce-vue";

export default {
  name: "TextContent",
  components: {
    InlineEditor,
    // editor: Editor, // <- Important part
  },
  data: () => ({
    timer: 0,
    savedNotification: false,
    failedNotification: false,
  }),
  mounted() {
    // console.log(this.data);
    // console.log(this.$editor)
  },
  props: {
    data: Object,
    perexLength: Number,
  },
  methods: {
    truncate,
    formatDate,
    save(content) {
      clearTimeout(this.timer);
      this.timer = setTimeout(this.saveAction, 1000, content);
    },
    async saveAction(content) {
      // console.log('aaa')
      // console.log(content);
      // console.log(content.target.dataset.id);
      // console.log(content.target.innerHTML);
      // console.log(this.$lang)
      // console.log(this.data.text);
      if (
        await updateContent("text", this.data.cmsid, {
          text: { [this.$lang]: content },
        })
      ) {
        this.savedNotification = true;
      } else {
        this.failedNotification = true;
      }

      setTimeout(() => {
        this.savedNotification = false;
        this.failedNotification = false;
      }, 3000);
    },
  },
};
</script>
