<template v-if="data">
  <div v-if="$editor" class="contact mx-7 mt-8">
    <v-hover>
      <template v-slot:default="{ hover }">
        <v-card @dragover="dragover" @dragleave="dragleave" @drop="drop">
          <picture>
            <v-img
              @click="show()"
              :src="thumbImgs[0]"
              width="180"
              :alt="data.Title"
            />
          </picture>
          <!-- <div class="mt-n6 "><v-icon v-if="$editor" >mdi-delete</v-icon></div> -->

          <v-fade-transition>
            <v-overlay v-if="hover && $editor" absolute color="#000">
              <v-btn color="primary" class="ma-auto px-0" title="změnit">
                <label for="file-upload" class="custom-file-upload mx-0">
                  <v-icon large class="link py-3 px-4 mx-auto"
                    >mdi-swap-horizontal</v-icon
                  >
                </label>
              </v-btn>
              <input
                id="file-upload"
                type="file"
                multiple
                :disabled="isSaving"
                @change="upload"
                accept="image/*"
                class="input-file"
              />

              <!--
              <v-btn color="primary" @click="change(i)" class="mx-1"
                ><v-icon title="změnit">mdi-swap-horizontal</v-icon></v-btn
              > -->
            </v-overlay>
          </v-fade-transition>
        </v-card>
      </template>
    </v-hover>
    <div>
      <b> <InlineEditor @input="save" v-model="form.name" /></b>
    </div>
    <InlineEditor @input="save" v-model="form.role" />
    <InlineEditor @input="save" v-model="form.email" />
    <InlineEditor @input="save" v-model="form.phone" />
    <vue-easy-lightbox
      moveDisabled
      :visible="visible"
      :imgs="imgs"
      :index="index"
      @hide="hide"
    ></vue-easy-lightbox>
  </div>
  <div v-else class="contact mx-7 mt-8">
    <v-img
      @click="show()"
      :src="thumbImgs[0]"
      width="180"
      :alt="data.Title"
    />
    <div>
      <b>{{ data.name }}</b>
    </div>
    <div v-html="data.role" />
    <div v-html="data.email" />
    <div v-html="data.phone" />
    <vue-easy-lightbox
      moveDisabled
      :visible="visible"
      :imgs="imgs"
      :index="index"
      @hide="hide"
    ></vue-easy-lightbox>
  </div>
</template>

<script>
import "./Contact.scss";
import InlineEditor from "../InlineEditor.vue";

import { updateContent, replaceAsset } from "../../api/api.js";
export default {
  name: "Contact",
  components: {
    InlineEditor,
  },
  data() {
    return {
      form: Object,
      isSaving: false,
      visible: false,
      index: 0,
      imgs: this.data.photo.map((x) => this.$assetsUrl + x + "?quality=85"),
      thumbImgs: this.data.photo.map((x) => this.$assetsUrl + x + "?width=180&height=220&mode=Crop&quality=85"),
    };
  },
  props: {
    data: Object,
  },
  methods: {
    log(msg) {
      console.log(msg);
    },

    show() {
      this.index = 0;
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
    save() {
      // console.log('saved');
      clearTimeout(this.timer);
      this.timer = setTimeout(this.saveAction, 1000);
    },
    async saveAction() {
      // console.log(this.data.cmsid);
      // console.log(this.form);
      if (
        // TO-DO:
        await updateContent("contact", this.data.cmsid, {
          email: { ["iv"]: this.form.email },
          name: { ["iv"]: this.form.name },
          role: { [this.$lang]: this.form.role },
          phone: { ["iv"]: this.form.phone },
        })
      ) {
        this.savedNotification = true;
      } else {
        this.failedNotification = true;
      }

      setTimeout(() => {
        this.savedNotification = false;
        this.failedNotification = false;
      }, 3000);
    },
    dragover(event) {
      event.preventDefault();
      // Add some vi  sual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      // this.$refs.file.files = event.dataTransfer.files;
      this.filesUpload(event.dataTransfer.name, event.dataTransfer.files);
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    upload(e) {
      this.filesUpload(e.target.name, e.target.files);
    },

    async filesUpload(fieldName, fileList) {
      // const fieldName = e.target.name
      // const fileList = e.target.files

      // const fileCount = e.target.files.length
      // console.log(fieldName);

      // console.log(fileList);
      // handle file changes
      const formData = new FormData();

      if (!fileList.length) return;
      // formData.append();
      // append the files to FormData
      Array.from(Array(fileList.length).keys()).map((x) => {
        formData.append("File", fileList[x], fileList[x].name);
      });
      // save it
      // this.save(formData);
      // console.log(...formData);
      const result = await replaceAsset(formData, this.data.photo);
      if (result) {
        console.log('replaced - reloading')
        this.imgs.splice(0,1,this.$assetsUrl + this.data.photo[0] + "?quality=85&ts=" + Date.now())
        this.thumbImgs.splice(0,1,this.$assetsUrl + this.data.photo[0] + "?width=180&height=220&mode=Crop&quality=85&ts=" + Date.now())
        // location.reload();
        // console.log(result);
        // console.log(result.id);
        // this.data.images.push(result.id);
        // updateContent("gallery", this.data.cmsid, {
          // images: { iv: this.data.images },
        // });
      }
    },
  },
  created() {
    if (this.$editor) {
      this.form = { ...this.data };
    }
    // console.log("contact")
    // console.log(this.data)
  },
  unmounted() {},
  mounted() {},
};
</script>

<style lang="scss">
.inblock {
  display: inline-block;
}
.contact {
  // height: 330px;
}

input[type="file"] {
  display: none;
}
</style>
