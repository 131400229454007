<template v-if="data">
  <div class="videos container-lg mt-5">
    <transition name="slide-fade">
      <v-alert
        class="notification"
        type="success"
        elevation="5"
        dense
        v-if="savedNotification"
        >Uloženo</v-alert
      >
      <v-alert
        class="notification"
        type="error"
        elevation="5"
        dense
        v-if="failedNotification"
        >Došlo k chybě!</v-alert
      >
    </transition>
    <v-container>
      <draggable
        v-if="$editor"
        class="row row--dense"
        animation="250"
        v-model="data.videos"
        draggable=".vid"
        @sort="onSort"
      >
        <v-col
          class="vid xcol-md-4 xcol-sm-6 xcol-xs-12"
          :cols="12"
          :md="4"
          :sm="4"
          :xs="12"
          v-for="(item, i) in data.videos"
          v-bind:key="i"
        >
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card>
                <iframe
                  width="385"
                  height="217"
                  :src="`https://www.youtube.com/embed/${item.ytcode}`"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <!-- <div class="mt-n6 "><v-icon v-if="$editor" >mdi-delete</v-icon></div> -->

                <v-fade-transition>
                  <v-overlay v-if="hover && $editor" absolute color="#000">
                    <v-btn
                      color="error"
                      @click="remove(i)"
                      title="smazat"
                      class="mx-0 px-0"
                      ><v-icon class="link py-2 px-4 mx-auto"
                        >mdi-delete</v-icon
                      ></v-btn
                    >
                  </v-overlay>
                </v-fade-transition>
              </v-card>
            </template>
          </v-hover>
        </v-col>

        <v-col
          v-if="$editor"
          class="xcol-md-4 xcol-sm-6 xcol-xs-12"
          :cols="12"
          :md="4"
          :sm="4"
          :xs="12"
        >
          <div class="placeholder justify-center d-flex">
            <div class="mt-2" style="position: absolute; color: #888">
              <small>Zadejte adresu youtube videa:</small>
              <v-text-field v-model="videoLink"> </v-text-field>
            </div>
            <v-btn outlined class="ma-auto pa-0" @click="addVideo">
              <v-icon x-large class="link py-3 px-4 mx-auto">mdi-plus</v-icon>
            </v-btn>
          </div>
        </v-col>
      </draggable>
      <v-row dense v-else>
        <v-col
          class="vid xcol-md-4 xcol-sm-6 xcol-xs-12"
          :cols="12"
          :md="4"
          :sm="4"
          :xs="12"
          v-for="(item, i) in data.videos"
          v-bind:key="i"
        >
          <v-hover>
            <template>
              <v-card>
                <iframe
                  width="385"
                  height="217"
                  :src="`https://www.youtube.com/embed/${item.ytcode}`"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </v-card>
            </template>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { updateContent } from "../../api/api.js";
import draggable from "vuedraggable";

export default {
  name: "Gallery",
  components: {
    draggable,
  },
  data() {
    return {
      visible: false,
      index: 0,
      isSaving: false,
      uploadFieldName: "images",
      savedNotification: false,
      failedNotification: false,
      videoLink: "",
    };
  },
  props: {
    data: Object,
  },
  methods: {
    log(msg) {
      console.log(msg);
    },
    onSort() {
      // console.log('sorted');
      // console.log(this.data.images);

      // this.imgs = this.data.images.map(
      //   (x) => this.$assetsUrl + x + "?quality=85"
      // );
      if (
        updateContent("videos", this.data.cmsid, {
          videos: { iv: this.data.videos },
        })
      ) {
        this.savedNotification = true;
      } else {
        this.failedNotification = true;
      }

      setTimeout(() => {
        this.savedNotification = false;
        this.failedNotification = false;
      }, 3000);
    },
    show(i) {
      this.index = i;
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
    addVideo() {
      // console.log(this.videoLink)
      const regexp = /.*(?:v=|youtu\.be\/)([\w.-]+)&?.*$/;

      var arr = this.videoLink.match(regexp) || [""];
      // console.log(arr[1])
      if (arr[1]) {
        this.data.videos.push({ ytcode: arr[1] });
      if (
        updateContent("videos", this.data.cmsid, {
          videos: { iv: this.data.videos },
        })
      ) {
        this.savedNotification = true;
        this.videoLink = "";
      } else {
        this.failedNotification = true;
      }

      setTimeout(() => {
        this.savedNotification = false;
        this.failedNotification = false;
      }, 3000);
      }
    },
    remove(i) {
      if (confirm("Potvrďte smazání")) {
        // let videos = this.data.videos;
        // images.remove(i)
        this.data.videos.splice(i, 1);
        // console.log(images)
      if (
        updateContent("videos", this.data.cmsid, {
          videos: { iv: this.data.videos },
        })
      ) {
        this.savedNotification = true;
      } else {
        this.failedNotification = true;
      }
      }
      // console.log(this.data.images[i])
      // removeContent('gallery', this.data.images[i])
    },
  },
};
</script>

<style lang="scss">
.videos {
  div.vid div.v-card {
    height: 217px;
  }
  @media only screen and (min-width: 1185px) {
    .container {
      max-width: 1185px;
    }
  }
  .placeholder {
    width: 100%;
    height: 100%;
    height: 217px;
    border: #333 1px dashed;
  }
}

.link {
  cursor: pointer;
}
</style>
