var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('editor',{staticClass:"pe-3",attrs:{"tinymceScriptSrc":"https://cdn.zionbit.eu/tinymce4/tinymce.min.js","init":{
    inline: true,
    plugins: ['link lists paste'],
    menubar: '',
    language: 'cs',
    force_br_newlines: false,
    forced_root_block: false,
    deprecation_warnings: false,
    entity_encoding: 'raw',
    toolbar:
      'bold italic underline | removeformat| alignleft aligncenter alignright alignjustify | fontsizeselect forecolor | link bullist |undo redo ',
  },"tag-name":"div","xstyle":"display: inline-block;"},on:{"input":_vm.updateValue},model:{value:(_vm.val),callback:function ($$v) {_vm.val=$$v},expression:"val"}})}
var staticRenderFns = []

export { render, staticRenderFns }