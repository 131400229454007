<template v-if="data">
  <div class="concerts">
    <transition name="slide-fade">
      <v-alert
        class="notification"
        type="success"
        elevation="5"
        dense
        v-if="savedNotification"
        >Uloženo</v-alert
      >
      <v-alert
        class="notification"
        type="error"
        elevation="5"
        dense
        v-if="failedNotification"
        >Došlo k chybě!</v-alert
      >
    </transition>
    <div class="container text-start">
      <v-row class="d-none d-lg-flex">
        <v-col cols="5"> Kdy a kde </v-col>
        <!-- <v-col cols="3"> Kde </v-col> -->
        <v-col cols="6"> Popis </v-col>
      </v-row>
      <div v-if="$editor">
        <v-row dense v-for="(concert, i) in form.concerts" :key="JSON.stringify(concert.date+i)">
          <!-- <v-col cols="6"><InlineEditor @input="save" v-model="concert.date" /></v-col> -->

          <v-col cols="6" class="datetimepicker" v-if="!saving"
            ><v-datetime-picker
              v-model="concert.date"
              clear="false"
              @input="save"
              date-format="dd.MM.yyyy"
              :time-picker-props="timeProps"
              :date-picker-props="dateProps"
            >
              <template slot="dateIcon">
                <v-icon>mdi-calendar</v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon>mdi-clock</v-icon>
              </template></v-datetime-picker
            ></v-col
          ><v-col cols="6" v-else
            ><span
              v-html="formatDateTime(concert.date)"
            /></v-col
          >
          <v-col cols="5"
            ><InlineEditor @input="save" v-model="concert.place"
          />
            </v-col>
            <v-col cols="1"
              >
            <v-icon @click="del(i)">mdi-delete</v-icon>
              </v-col>
          <v-col cols="12"
            ><InlineEditor @input="save" v-model="concert.description"
          /></v-col>
        </v-row>
        <div class="text-center mt-2">
          <v-icon large @click="add">mdi-plus</v-icon>
        </div>
      </div>
      <div v-else>
        <v-row v-for="(concert, i) in data.concerts" :key="i">
          <!-- <v-col cols="6" class="my-0 py-1" lg="3" v-html="formatDateTime(concert.date)" />
          <v-col cols="6" class="my-0 py-1" lg="3" v-html="concert.place" /> -->
          <v-col
            cols="12"
            class="
              my-0
              pt-2 pt-lg-1
              pb-0 pb-lg-1
              font-weight-bold
              text-body-2 text-lg-body-1
            "
            lg="5"
            ><span v-html="formatDateTime(concert.date)" /> -
            <span v-html="concert.place"
          /></v-col>
          <v-col
            cols="12"
            class="my-0 pt-0 pt-lg-1 pb-2 pb-lg-1 text-body-2 text-lg-body-1"
            lg="7"
            v-html="concert.description"
          />
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import "./Concert.scss";
import { formatDateTime } from "../../helpers/formatDate";

import { updateContent } from "../../api/api.js";

import InlineEditor from "../InlineEditor.vue";

export default {
  name: "Concert",
  components: {
    InlineEditor,
  },
  data: () => ({
    savedNotification: false,
    failedNotification: false,
    form: Object,
    timer: null,
    saving: false,
    dateProps: {
      "first-day-of-week": 1,
      locale: "cs-CZ",
    },
    timeProps: {
      format: "24hr",
      locale: "cs-CZ",
    },
  }),
  props: {
    data: Object,
  },
  methods: {
    formatDateTime,
    log(msg) {
      console.log(msg);
    },
    add(){
      // this.form.concerts.push({
      //   place: "",
      //   date: new Date(),
      //   description: ""
      // })

      this.form.concerts.splice(this.form.concerts.length, 1, {
        place: "",
        date: new Date(),
        description: ""
      })
      // console.log(this.form.concerts)
      // this.form.concerts = this.form.concerts.splice(this.form.concerts.length, 1, {
      //   place: "",
      //   date: new Date(),
      //   description: ""
      // })
      // this.form.concerts.splice(this.form.concerts.length)
      // this.form.splice(this.form.length)
      // this.form.concerts.splice(this.events.indexOf(event), 1);
    },
    del(i){
      // console.log()
      this.form.concerts.splice(i, 1)
      this.saveAction()

      // console.log(this.form.concerts)
    },
    save() {
      // console.log('saved');
      clearTimeout(this.timer);
      this.timer = setTimeout(this.saveAction, 1000);
    },
    async saveAction() {
      // console.log(this.data.cmsid);
      this.saving=true
      this.form.concerts.map(function (concert) {
        concert.date = concert.date.toISOString();
        return concert;
      });
      if (
        // TO-DO:

        await updateContent("concerts", this.data.cmsid, {
          concerts: { ["iv"]: this.form.concerts },
        })
      ) {
        this.savedNotification = true;
      } else {
        this.failedNotification = true;
      }
      // console.log(this.form.concerts);
      this.form.concerts.map(function (concert) {
        concert.date = new Date(concert.date);
        return concert;
      });

      this.saving=false
      setTimeout(() => {
        this.savedNotification = false;
        this.failedNotification = false;
      }, 3000);
    },
  },
  created() {
    if (this.$editor) {
      this.form = { ...this.data };
      // console.log(this.form.concerts)
      this.form.concerts.map(function (concert) {
        concert.date = new Date(concert.date);
        return concert;
      });
    }
  },
};
</script>
<style lang="scss">
div.concerts {
  max-width: 980px;
  padding: 10px;
  border-radius: 1em;
  border: 1px solid #f25a26;
  margin: 30px auto 0 auto;
  text-align: center;
}
.datetimepicker {
  .v-input {
    padding: 1px 0;
    margin: 0;
  }
  .v-input__slot {
    margin: 0;
  }
  .v-text-field__details {
    display: none;
  }
}
</style>
