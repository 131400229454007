<template v-if="data">
  <div class="gallery container-lg mt-5">
    <!-- {{data.title}} -->

      <transition name="slide-fade">
        <v-alert
          class="notification"
          type="success"
          elevation="5"
          dense
          v-if="savedNotification"
          >Uloženo</v-alert
        >
        <v-alert
          class="notification"
          type="error"
          elevation="5"
          dense
          v-if="failedNotification"
          >Došlo k chybě!</v-alert
        >
      </transition>
    <v-container>
      <draggable
        v-if="$editor"
        class="row row--dense"
        animation="250"
        v-model="data.images"
        draggable=".img"
        @sort="onSort"
      >
        <v-col
          class="img xcol-md-4 xcol-sm-6 xcol-xs-12"
          :cols="12"
          :md="4"
          :sm="4"
          :xs="12"
          v-for="(item, i) in data.images"
          v-bind:key="i"
        >
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card>
                <picture>
                  <v-img
                    @click="show(i)"
                    :src="
                      $assetsUrl +
                      item +
                      '?width=370&height=200&mode=Crop&quality=85'
                    "
                    :alt="data.Title"
                  />
                </picture>
                <!-- <div class="mt-n6 "><v-icon v-if="$editor" >mdi-delete</v-icon></div> -->

                <v-fade-transition>
                  <v-overlay v-if="hover && $editor"  title="zobrazit"  absolute color="#000">
                    <v-btn color="primary" @click="show(i)" class="mx-1 px-0"
                      ><v-icon class="link py-2 px-4 mx-auto">mdi-fullscreen</v-icon></v-btn
                    >
                    <v-btn color="error" @click="remove(i)" title="smazat" class="mx-1 px-0"
                      ><v-icon class="link py-2 px-4 mx-auto">mdi-delete</v-icon></v-btn
                    >
                  </v-overlay>
                </v-fade-transition>
              </v-card>
            </template>
          </v-hover>
        </v-col>

        <v-col
          v-if="$editor"
          class=" xcol-md-4 xcol-sm-6 xcol-xs-12"
          :cols="12"
          :md="4"
          :sm="4"
          :xs="12"
        >
          <div
            class="placeholder justify-center d-flex"
            @dragover="dragover"
            @dragleave="dragleave"
            @drop="drop"
          >
            <div class="mt-1" style="position: absolute; color: #888">
              <small
                >Přidej obrázky přetáhnutím,<br />nebo vyber pomocí
                tlačítka:</small
              >
            </div>
            <v-btn outlined class="ma-auto pa-0">
              <label for="file-upload" class="custom-file-upload">
                <v-icon x-large class="link py-3 px-4 mx-auto">mdi-plus</v-icon>
              </label>
            </v-btn>
            <input
              id="file-upload"
              type="file"
              multiple
              :name="uploadFieldName"
              :disabled="isSaving"
              @change="upload"
              accept="image/*"
              class="input-file"
            />
          </div>
        </v-col>
      </draggable>
    <v-row dense v-else>
      <v-col
        class="img xcol-md-4 xcol-sm-6 xcol-xs-12"
        :cols="12"
        :md="4"
        :sm="4"
        :xs="12"
        v-for="(item, i) in data.images"
        v-bind:key="i"
      >
        <v-hover>
          <template>
            <v-card>
              <picture>
                <v-img
                  @click="show(i)"
                  :src="
                    $assetsUrl +
                    item +
                    '?width=370&height=200&mode=Crop&quality=85'
                  "
                  :alt="data.Title"
                />
              </picture>
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>

    <vue-easy-lightbox
      moveDisabled
      :visible="visible"
      :imgs="imgs"
      :index="index"
      @hide="hide"
    ></vue-easy-lightbox>
  </div>
</template>

<script>
import "./Gallery.scss";
import { updateContent, uploadAsset } from "../../api/api.js";
import draggable from "vuedraggable";

export default {
  name: "Gallery",
  components: {
    draggable,
  },
  data() {
    return {
      visible: false,
      index: 0,
      imgs: this.data.images.map((x) => this.$assetsUrl + x + "?quality=85"),
      isSaving: false,
      uploadFieldName: "images",
      savedNotification: false,
      failedNotification: false,
    };
  },
  props: {
    data: Object,
  },
  methods: {
    log(msg) {
      console.log(msg);
    },
    onSort() {
      // console.log('sorted');
      // console.log(this.data.images);
      this.imgs = this.data.images.map(
        (x) => this.$assetsUrl + x + "?quality=85"
      );
      if (
        updateContent("gallery", this.data.cmsid, {
          images: { iv: this.data.images },
        })
      ) {
        this.savedNotification = true;
      } else {
        this.failedNotification = true;
      }

      setTimeout(() => {
        this.savedNotification = false;
        this.failedNotification = false;
      }, 3000);
    },
    show(i) {
      this.index = i;
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
    remove(i) {
      if (confirm("Potvrďte smazání")){
        let images = this.data.images;
        // images.remove(i)
        images.splice(i, 1);
        // console.log(images)
        updateContent("gallery", this.data.cmsid, { images: { iv: images } });
      }
      // console.log(this.data.images[i])
      // removeContent('gallery', this.data.images[i])
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      // this.$refs.file.files = event.dataTransfer.files;
      this.filesUpload(event.dataTransfer.name, event.dataTransfer.files);
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    upload(e) {
      this.filesUpload(e.target.name, e.target.files);
    },

    async filesUpload(fieldName, fileList) {
      // const fieldName = e.target.name
      // const fileList = e.target.files

      // const fileCount = e.target.files.length
      console.log(fieldName);

      console.log(fileList);
      // handle file changes
      const formData = new FormData();

      if (!fileList.length) return;
      // formData.append();
      // append the files to FormData
      Array.from(Array(fileList.length).keys()).map((x) => {
        formData.append("File", fileList[x], fileList[x].name);
      });

      // save it
      // this.save(formData);
      console.log(...formData);
      const result = await uploadAsset(formData);
      if (result) {
        console.log(result);
        console.log(result.id);
        this.data.images.push(result.id);
        updateContent("gallery", this.data.cmsid, {
          images: { iv: this.data.images },
        });
      }
    },
  },
};
</script>

<style lang="scss">
.gallery {
  @media only screen and (min-width: 1185px) {
    .container {
      max-width: 1185px;
    }
  }
  .placeholder {
    &.bg-green-300 {
      background-color: rgba(30, 30, 30, 200);
    }
    width: 100%;
    height: 100%;
    min-height: 160px;
    border: #333 1px dashed;
  }
  input[type="file"] {
    display: none;
  }
}

.link {
  cursor: pointer;
}
</style>
