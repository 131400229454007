import Vue from "vue";
import App from "./App.vue";
// import router from "./router";
import VueRouter from "vue-router";
import vuetify from "./plugins/vuetify";
import Page from "@/views/Page.vue";
// import Homepage from "@/views/Homepage.vue";
import VueEasyLightbox from "vue-easy-lightbox";
import DatetimePicker from "vuetify-datetime-picker"

Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(VueEasyLightbox);
Vue.use(DatetimePicker)

const routes = [
  { path: "/", component: Page },
  { path: "/test", component: Page },
  { name: "Page", path: "/:slug", component: Page },
  // { name: "Auth", path: '/auth', component: Auth },
];

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = new VueRouter({
  mode: "history",
  routes: routes,
});

Vue.prototype.$lang = "cs";
Vue.prototype.$assetsUrl = process.env.VUE_APP_ASSETS_URL;
Vue.prototype.$editor = localStorage.getItem("apiSecret") ? true : false;

new Vue({
  vuetify,
  router,
  render: (h) => h(App),
}).$mount("#app");
