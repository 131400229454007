<template v-if="data">
  <v-container fluid class="image pa-0 ma-0 mt-n6 pt-0">
    <!-- <vueper-slides
      :slideRatio="440 / 1920"
      :breakpoints="breakpoints"
      autoplay
      fade
      transition-speed="1000"
      duration="5000"
    >
      <vueper-slide
        v-for="(slide, i) in data.images"
        :key="i"
        :image="$assetsUrl + slide + '?width=1920&height=440&mode=Crop&quality=85'"
      />
    </vueper-slides> -->
    <v-img
      class="d-flex d-sm-none"
      :src="
        $assetsUrl +
        data.image[0] +
        '?width=600&height=350&mode=Crop&quality=85'
      "
    />
    <v-img
      class="d-none d-sm-flex"
      :src="
        $assetsUrl +
        data.image[0] +
        '?width=1920&height=440&mode=Crop&quality=85'
      "
    />
  </v-container>
</template>

<script>
import "./Image.scss";

// import { VueperSlides, VueperSlide } from "vueperslides";
// import "vueperslides/dist/vueperslides.css";

export default {
  // components: { VueperSlides, VueperSlide },

  name: "ImageContent",
  props: {
    data: Object,
    perexLength: Number,
  },
  methods: {},
  mounted() {
    // console.log(this.data)
  },
};
</script>
