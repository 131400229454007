
import dayjs from "dayjs";

export function formatDate(value){
  if (value) {
    const lang = localStorage.getItem("lang");
    if (lang=='en'){
      return dayjs(String(value)).format('DD MMMM YYYY')
    } else {
      return dayjs(String(value)).format('DD.MM.YYYY')
    }
  } else {
  return null;
  }

}

export function formatDateTime(value){
  if (value) {
    const lang = localStorage.getItem("lang");
    if (lang=='en'){
      return dayjs(String(value)).format('DD MMMM YYYY hh:mm')
    } else {
      return dayjs(String(value)).format('DD.MM.YYYY HH:mm')
    }
  } else {
  return null;
  }

}
