<template>
  <v-app>
    <v-main>
      <v-alert
        v-if="$editor"
        type="info"
        elevation="5"
        class="notification editor"
        dense
      >
        Režim editace! <v-btn small @click="dropEditor">ukončit</v-btn></v-alert
      >

      <Menu v-if="menuData && initSlug" :data="menuData" :initSlug="initSlug" />

      <keep-alive>
        <router-view
          :key="$route.fullPath"
          v-if="initSlug"
          :initSlug="initSlug"
        />
      </keep-alive>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Menu from "./components/Menu";
import Footer from "./components/Footer";
import { getMenu } from "./api/api.js";
import "@/assets/scss/main.scss";

export default {
  name: "App",

  components: {
    Menu,
    Footer,
  },

  data: () => ({
    menuData: {},
    initSlug: "",
    //
  }),
  async created() {
    if (this.$route.query.secret) {
      localStorage.setItem("apiSecret", this.$route.query.secret);
      localStorage.removeItem("token");
      let query = Object.assign({}, this.$route.query);
      delete query.secret;
      this.$router.replace({ query });
      location.reload();
    }
    const menu = await getMenu(this.$lang);
    this.menuData = menu[0];
    // console.log(this.menuData);
    this.initSlug = this.menuData.homepage.slug[this.$lang];
  },
  methods: {
    dropEditor() {
      localStorage.removeItem("apiSecret");
      localStorage.removeItem("token");
      location.reload();
    },
  },
};
</script>
