<template>
  <v-container class="Albums text-center mx-auto">
    <transition name="slide-fade">
      <v-alert
        class="notification"
        type="success"
        elevation="5"
        dense
        v-if="savedNotification"
        >Uloženo</v-alert
      >
      <v-alert
        class="notification"
        type="error"
        elevation="5"
        dense
        v-if="failedNotification"
        >Došlo k chybě!</v-alert
      >
    </transition>
    <Carousel-3d
      v-if="active"
      :controls-visible="true"
      :width="260"
      :height="260"
      class="mx-auto"
      :count="data.content.length"
      :display="5"
      :loop="true"
      :animationSpeed="300"
      @before-slide-change="pickAlbumTimer"
    >
      <Slide
        v-for="(slide, i) in data.content"
        :index="parseInt(i)"
        :key="parseInt(i)"
      >
        <!-- <img :src="require('@/assets/images/obal5.jpg')" /> -->
        <figure
        @click="pickAlbum(i)">
          <img
            :src="
              $assetsUrl +
              slide.image[0] +
              '?width=260&height=260&mode=Crop&quality=85'
            "
          />
          <figcaption>
            {{ slide.title }}
          </figcaption>
        </figure>

        <!-- <template slot-scope="{ index, isCurrent, leftIndex, rightIndex }"> -->
        <!-- <img :data-index="index" :class="{ current: isCurrent, onLeft: (leftIndex >= 0), onRight: (rightIndex >= 0) }" :src="slide.src"> -->
        <!-- </template> -->
      </Slide>
    </Carousel-3d>

    <div v-if="$editor" v-bind:key="activeAlbum">
      <v-row justify="center">
        <v-col
          cols="8"
          md="6"
          lg="6"
          class="description text-center mx-auto body-2"
        >
          <InlineEditor
            @input="save"
            v-model="form.content[activeAlbum].description"
          />
        </v-col>
      </v-row>
      <v-row
        v-if="form.content[activeAlbum].songs"
        class="mt-7"
        justify="center"
      >
        <v-col cols="4" md="3" lg="3" class="text-start">
          <div
            v-for="(song, i) in form.content[activeAlbum].songs"
            v-bind:key="song.cmsid"
          >
            <span
              :class="[
                'song',
                {
                  'warning--text font-weight-medium': i === activeSong,
                  'text--secondary': i !== activeSong,
                },
              ]"
              ><InlineEditor
                @input="save"
                v-model="song.name"
                style="display: inline-block"
              /><span @click="pickSong(i)"
                ><v-icon>mdi-chevron-right</v-icon></span
              ></span
            >
          </div>
        </v-col>
        <v-col
          cols="8"
          sm="6"
          md="5"
          lg="5"
          class="text-start"
          :key="activeSong"
        >
        <vuetify-audio
          class="audioplayer"
          v-if="
            data.content[activeAlbum].songs[activeSong].audio && audioReady
          "
          :file="$assetsUrl + data.content[activeAlbum].songs[activeSong].audio +
          '/' +
          data.content[activeAlbum].songs[activeSong].name +
          '.mp3' + ts"
          ref="audio"
        />
        <div class="text-center"
        v-if="
          data.content[activeAlbum].songs[activeSong].audio
        ">

          <v-btn color="primary" class="ma-auto pa-0" title="změnit MP3">
            <label for="file-replace" class="custom-file-upload mx-0">
              <v-icon class="link py-2 px-4 mx-auto"
                >mdi-swap-horizontal</v-icon
              >
            </label>
          </v-btn>

          <input
            id="file-replace"
            type="file"
            multiple
            :disabled="isSaving"
            @change="replace"
            accept="audio/*"
            class="input-file"
          />
        <v-btn color="error" class="ma-1 pa-0" title="smazat MP3" @click="removeAsset"><v-icon class="link py-2 px-4 mx-auto">mdi-delete</v-icon></v-btn>

        </div>
        <div class="text-center"
        v-else>

          <v-btn color="success" class="ma-auto pa-0" title="nahrát MP3">
            <label for="file-upload" class="custom-file-upload mx-0">
              <v-icon large class="link py-2 px-4 mx-auto"
                >mdi-plus</v-icon
              >
            </label>
          </v-btn>

        <input
          id="file-upload"
          type="file"
          multiple
          :disabled="isSaving"
          @change="upload"
          accept="audio/*"
          class="input-file"
        />
        </div>
          <div class="body-2 mb-4 mt-5">
            text:
            <InlineEditor
              style="display: inline-block"
              @input="save"
              v-model="form.content[activeAlbum].songs[activeSong].authorText"
            /><br />
            hudba:
            <InlineEditor
              style="display: inline-block"
              @input="save"
              v-model="form.content[activeAlbum].songs[activeSong].authorMusic"
            />
          </div>

          <InlineEditor
            @input="save"
            class="body-2"
            v-model="form.content[activeAlbum].songs[activeSong].lyrics"
          />
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-row justify="center">
        <v-col
          cols="8"
          md="6"
          lg="5"
          class="description text-center mx-auto body-2"
          v-html="data.content[activeAlbum].description"
        />
      </v-row>
      <v-row
        v-if="data.content[activeAlbum].songs"
        class="mt-7"
        justify="center"
      >
        <v-col cols="4" md="3" lg="2" class="text-start">
          <div
            v-for="(song, i) in data.content[activeAlbum].songs"
            v-bind:key="song.cmsid"
            @click="pickSong(i)"
          >
            <span
              :class="[
                'song',
                {
                  'warning--text font-weight-medium': i === activeSong,
                  'text--secondary': i !== activeSong,
                },
              ]"
              >{{ song.name }}</span
            >
          </div>
        </v-col>
        <v-col cols="8" sm="6" md="5" lg="4" class="text-start">
          <vuetify-audio
            class="mb-5 audioplayer"
            v-if="
              data.content[activeAlbum].songs[activeSong].audio && audioReady
            "
            :file="
              $assetsUrl +
              data.content[activeAlbum].songs[activeSong].audio +
              '/' +
              data.content[activeAlbum].songs[activeSong].name +
              '.mp3'
            "
            ref="audio"
          />
          <div class="body-2 mb-4"><div v-if="data.content[activeAlbum].songs[activeSong].authorText">
            text:
            <span
              v-html="data.content[activeAlbum].songs[activeSong].authorText"
            /></div>
            <div v-if="data.content[activeAlbum].songs[activeSong].authorMusic">
            hudba:
            <span
              v-html="data.content[activeAlbum].songs[activeSong].authorMusic"
            />
            </div>
          </div>
          <div
            class="body-2"
            v-html="data.content[activeAlbum].songs[activeSong].lyrics"
          />
        </v-col>
      </v-row>
    </div>
    <!-- <div
      v-for="(item, i) in data.content"
      v-bind:key="i"
      class="col-lg-2 col-md-4 col-6 p-0 mx-auto"
    >
      <router-link :to="item.link ? item.link : ''">
        <img
          :src="$assetsUrl + item.image + '?width=240'"
          alt=""
          width="120"
          height="120"
        /><br />{{ item.title }}
      </router-link>
    </div> -->
  </v-container>
</template>

<script>
import "./Albums.scss";
import { Carousel3d, Slide } from "vue-carousel-3d";
import { updateContent, uploadAsset, replaceAsset } from "../../api/api.js";

import InlineEditor from "../InlineEditor.vue";

export default {
  components: {
    Carousel3d,
    Slide,
    InlineEditor,
    VuetifyAudio: () => import("vuetify-audio"),
  },
  data() {
    return {
      form: null,
      activeAlbum: 0,
      activeSong: 0,
      savedNotification: false,
      failedNotification: false,
      audioReady: true,
      active: true,
      isSaving: false,
      ts: ''
    };
  },
  name: "Albums",
  props: {
    data: Object,
  },
  activated(){
    this.active=true
  },
  deactivated(){
    this.active=false
  },
  methods: {

    pickAlbumTimer(v) {
      // console.log("saved");
      clearTimeout(this.timer);
      this.timer = setTimeout(this.pickAlbum, 170, v);
    },
    pickAlbum(index) {
      console.log(index)
      if(this.activeAlbum != index){
        this.activeAlbum = index;
        this.pickSong(0);
      }

    },
    pickSong(i) {
      // console.log("Song " + i);
      this.audioReady = false;
      this.activeSong = i;
      this.$nextTick().then(() => {
        this.audioReady = true;
      });
    },
    save() {
      // console.log("saved");
      clearTimeout(this.timer);
      this.timer = setTimeout(this.saveAction, 1000);
    },
    async saveAction() {
      // console.log(this.form.content[this.activeAlbum].cmsid);
      if (
        // TO-DO:
        await updateContent(
          "album",
          this.form.content[this.activeAlbum].cmsid,
          {
            title: { [this.$lang]: this.form.content[this.activeAlbum].title },
            description: {
              [this.$lang]: this.form.content[this.activeAlbum].description,
            },
            songs: { ["iv"]: this.form.content[this.activeAlbum].songs },
          }
        )
      ) {
        this.savedNotification = true;
      } else {
        this.failedNotification = true;
      }

      setTimeout(() => {
        this.savedNotification = false;
        this.failedNotification = false;
      }, 3000);
    },
    upload(e) {
      this.filesUpload(e.target.name, e.target.files);
    },

    async filesUpload(fieldName, fileList) {
      const formData = new FormData();

      if (!fileList.length) return;
      // formData.append();
      // append the files to FormData
      Array.from(Array(fileList.length).keys()).map((x) => {
        formData.append("File", fileList[x], fileList[x].name);
      });

      // save it
      // this.save(formData);
      // console.log(...formData);
      const result = await uploadAsset(formData);
      if (result) {
        // this.data.images.push(result.id);

          this.form.content[this.activeAlbum].songs[this.activeSong].audio = [result.id];
          this.save()
          // this.audioFile = this.$assetsUrl + this.data.content[this.activeAlbum].songs[this.activeSong].audio +
          // '/' +
          // this.data.content[this.activeAlbum].songs[this.activeSong].name +
          // '.mp3?reload'
        // updateContent("gallery", this.data.cmsid, {
        //   images: { iv: this.data.images },
        // });
        }
    },

    replace(e) {
      this.fileReplace(e.target.name, e.target.files);
    },
    async fileReplace(fieldName, fileList) {
      const formData = new FormData();

      if (!fileList.length) return;
      // formData.append();
      // append the files to FormData
      Array.from(Array(fileList.length).keys()).map((x) => {
        formData.append("File", fileList[x], fileList[x].name);
      });

      const result = await replaceAsset(formData, this.form.content[this.activeAlbum].songs[this.activeSong].audio[0]);
      if (result) {
        // location.reload();

        this.audioReady = false;
        this.ts='?ts='+ Date.now();
        this.$nextTick().then(() => {
          this.audioReady = true;
        });
      }
    },
    removeAsset() {
      if (confirm("Potvrďte smazání")){
        this.form.content[this.activeAlbum].songs[this.activeSong].audio = null;
        this.save()
      }
    },
  },
  created() {
    // console.log(this.data.content);
    if (this.$editor) {
      this.form = { ...this.data };
    }
  },
};
</script>

<style lang="scss">
span.song {
  cursor: pointer;
  line-height: 2;
  :hover {
    text-decoration: underline;
  }
}
.carousel-3d-container figure {
  margin: 0;
}

.carousel-3d-container figcaption {
  position: absolute;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  bottom: 0;
  position: absolute;
  font-weight: bold;
  bottom: 0;
  padding: 5px;
  font-size: 14px;
  min-width: 100%;
  box-sizing: border-box;
}
.audioplayer {
  .v-card__text {
    padding: 5px 10px;
  }

  .v-progress-linear {
    margin: 6px !important;
  }

  .v-btn.v-btn--icon {
    width: 33px;
    height: 33px;
    margin: 4px 7px!important;
  }
  p {
    margin-bottom: 3px;
  }
}
</style>
